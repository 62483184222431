<template>
    <el-upload class="avatar-uploader" action="/api/upload" :headers="headers" :show-file-list="false"
        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
</template>

<script>
export default {
    name: 'upload-image',
    props: ['value'],
    data() {
        return {
            imageUrl: this.value || ''
        };
    },
    watch: {
        value: {
            handler(newValue) {
                this.imageUrl = newValue || ''
            }
        },
    },
    computed: {
        headers() {
            const token = localStorage.getItem('token')
            return {
                Authorization: `Bearer ${token}`
            }
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            console.log(res)
            if (res.status == 'success') {
                // this.imageUrl = URL.createObjectURL(file.raw);
                this.imageUrl = res.data[0].url
                this.$emit('input', this.imageUrl)
            } else {
                this.$message.error(res.msg)
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = ['image/png', 'image/jpeg'].includes(file.type);
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    }
}
</script>

<style scoped>
.avatar-uploader ::v-deep.el-upload {
    margin: 0 10px;
    border: 1px dashed #ccc;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader ::v-deep.el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 160px;
    line-height: 200px;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 160px;
    display: block;
}
</style>
