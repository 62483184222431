import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        username: '',
        token: '',
        openid:"",
        userType: "",
        app: {
            version: '',
        },
        messages: []
    },
    mutations: {
        set_username (state, username) {
            localStorage.setItem('username', username)
            state.username = username
        },
        set_token (state, token) {
            localStorage.setItem('token', token)
            state.token = token
        },
        set_openid (state, openid) {
            localStorage.setItem('openid', openid)
            state.openid = openid
        },
        set_userType (state, userType) {
            localStorage.setItem('userType', userType)
            state.userType = userType
        },
    },
    getters: {
        username: state => {
            return state.username || localStorage.getItem('username')
        },
        token: state => {
            return state.token || localStorage.getItem('token')
        },
        openid: state => {
            return state.openid || localStorage.getItem('openid')
        },
        userType: state => {
            return state.userType || localStorage.getItem('userType')
        }
    }
})

export default store
