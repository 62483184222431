<template>
    <el-popover trigger="click" placement="top">
        <div v-for="(item,index) in tagList.level_tag" :key="index" style="padding: 5px">
            <el-tag type="danger" size="medium" >{{item}}</el-tag>
        </div>
        <div  v-if="tagList.audience_tag" style="padding: 5px">
            <el-tag size="medium" >{{tagList.audience_tag}}</el-tag>
        </div>
        <div  v-if="tagList.user_level_tag" style="padding: 5px">
            <el-tag size="medium" type="warning">{{tagList.user_level_tag}}</el-tag>
        </div>
        <div slot="reference" class="name-wrapper">
            <el-tag size="medium">共{{ tagList|tagListFormatter }}个标签</el-tag>
        </div>
    </el-popover>
</template>

<script>
export default {
    name: 'popover-JCXM',
    props: {
        tagList: {
            default: []
        }
    },
    data() {
        return {
            loading: false,
            tableData: {}
        }
    },
    filters: {
        tagListFormatter: function (item) {
            var count = 0;
            if(item.audience_tag){
                count += 1
            }
            if(item.user_level_tag){
                count += 1
            }
            if(item.level_tag){
                count += item.level_tag.length
            }
            return count;
        }

    },
    methods: {
    }
}
</script>
