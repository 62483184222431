<template>
    <div style="height: 500px;overflow-y: auto;">
        <div style="font-weight: 700;padding:10px">生活信息</div>
        <el-row>
            <el-form-item label="饮食习惯" :label-width="labelwidth">
                <el-input v-model="form.lifeInfo.foods" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="睡眠情况" :label-width="labelwidth">
                <el-input v-model="form.lifeInfo.sleep" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="吸烟史" :label-width="labelwidth">
                <el-input v-model="form.lifeInfo.smoke" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="饮酒史" :label-width="labelwidth">
                <el-input v-model="form.lifeInfo.drink" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="生活作息" :label-width="labelwidth">
                <el-input v-model="form.lifeInfo.daily" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="日常生活方式" :label-width="labelwidth">
                <el-input v-model="form.lifeInfo.style" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
        </el-row>
        <div style="font-weight: 700;padding:10px">生活环境</div>
        <el-row>
            <el-col :span="12">
                <el-form-item label="厨房排风设施" :label-width="labelwidth">
                    <el-select v-model="form.environment.exhaust" placeholder="请选择" style="width:100%" clearable>
                        <el-option v-for="item in exhaustList" :key="item.value" :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="燃料类型" :label-width="labelwidth">
                    <el-select v-model="form.environment.energy" placeholder="请选择" style="width:100%" clearable>
                        <el-option v-for="item in energyList" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="饮水" :label-width="labelwidth">
                    <el-select v-model="form.environment.water" placeholder="请选择" style="width:100%" clearable>
                        <el-option v-for="item in waterList" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="厕所" :label-width="labelwidth">
                    <el-select v-model="form.environment.toilet" placeholder="请选择" style="width:100%" clearable>
                        <el-option v-for="item in toiletList" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="禽兽栏" :label-width="labelwidth">
                    <el-select v-model="form.environment.acorral" placeholder="请选择" style="width:100%" clearable>
                        <el-option v-for="item in acorralList" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'lifeInfoForm',
        props: {
            labelwidth: {
                type: String,
                default: "120px"
            },
            form: {},
            btnBgColor: {
                type: String,
                default: "#9089fa"
            },
            btnColor: {
                type: String,
                default: "#ffffff"
            },

        },
        data() {
            return {
                loading: false,
                dataList:[
                    {label:"高血压",value:"1"},
                    {label:"糖尿病",value:"2"},
                    {label:"冠心病",value:"3"},
                    {label:"慢性阻塞性肺疾病",value:"4"},
                    {label:"恶性肿瘤",value:"5"},
                    {label:"脑辛中",value:"6"},
                    {label:"严重精神障碍",value:"7"},
                    {label:"结核病",value:"8"},
                    {label:"肝炎",value:"9"},
                    {label:"先天畸形",value:"10"},
                    {label:"心脏病",value:"11"},
                    {label:"脑梗",value:"12"},
                    {label:"血脂异常",value:"13"},
                    {label:"哮喘",value:"14"},
                    {label:"其它",value:"15"},
                ],
                exhaustList:[
                    {label:"无",value:"1"},
                    {label:"油烟机",value:"2"},
                    {label:"换气扇",value:"3"},
                    {label:"烟囱",value:"4"},
                ],
                energyList:[
                    {label:"液化气",value:"1"},
                    {label:"煤",value:"2"},
                    {label:"天然气",value:"3"},
                    {label:"沼气",value:"4"},
                    {label:"柴火",value:"5"},
                    {label:"其他",value:"6"},
                ],
                waterList:[
                    {label:"自来水",value:"1"},
                    {label:"经净化过滤的水",value:"2"},
                    {label:"井水",value:"3"},
                    {label:"河湖水",value:"4"},
                    {label:"塘水",value:"5"},
                    {label:"其他",value:"6"},
                ],
                toiletList:[
                    {label:"卫生厕所",value:"1"},
                    {label:"一格或二格粪池式",value:"2"},
                    {label:"马桶",value:"3"},
                    {label:"露天粪坑",value:"4"},
                    {label:"简易棚厕",value:"5"},
                ],
                acorralList:[
                    {label:"无",value:"1"},
                    {label:"单设",value:"2"},
                    {label:"室内",value:"3"},
                    {label:"室外",value:"4"},
                ]
            }
        },
        methods: {
            handelIllness(v, type, items) {
                if (v == 1 && this.form.pastHistory[type].list.length == 0) {
                    this.form.pastHistory[type].list.push(items)
                }
            },
            handeAdd(type, items) {
                this.form.pastHistory[type].list.push(items)
            },
            handelDel(index, type) {
                this.form.pastHistory[type].list.splice(index, 1)
            }
        },
    };
</script>

<style scoped>
    /deep/ .el-radio-button__inner, .el-radio-group {
        display: inline-block;
        vertical-align: middle;
    }

    /deep/ .el-input-group__append, .el-input-group__prepend {
        border: 1px solid #ffffff;
        border-left: 1px solid #f7f7f7;
    }
</style>
