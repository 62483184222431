<template>
    <div style="height: 500px;overflow-y: auto;">
        <div style="font-weight: 700;padding:10px">家庭情况</div>
        <el-row>
            <el-col :span="12">
                <el-form-item label="婚姻状态" :label-width="labelwidth">
                    <el-select v-model="form.patientInfo.marital" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="未婚" value="1"></el-option>
                        <el-option label="已婚" value="2"></el-option>
                        <el-option label="丧偶" value="3"></el-option>
                        <el-option label="离异" value="4"></el-option>
                        <el-option label="未知" value="5"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="生育状态" :label-width="labelwidth">
                    <el-select v-model="form.patientInfo.fertility" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="未育" value="1"></el-option>
                        <el-option label="已育" value="2"></el-option>
                        <el-option label="未知" value="3"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="font-weight: 700;padding:10px">家族史</div>
        <el-row>
            <el-form-item label="父亲" :label-width="labelwidth">
                <el-select v-model="form.familyHistory.fater.value" multiple placeholder="请选择" style="width:100%"
                           clearable>
                    <el-option :label="items.label" :value="items.value" v-for="(items,index) in dataList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他" :label-width="labelwidth" v-if="form.familyHistory.fater.value.includes('15')">
                <el-input v-model="form.familyHistory.fater.remark">
                </el-input>
            </el-form-item>
            <el-form-item label="母亲" :label-width="labelwidth">
                <el-select v-model="form.familyHistory.monter.value" multiple placeholder="请选择" style="width:100%"
                           clearable>
                    <el-option :label="items.label" :value="items.value" v-for="(items,index) in dataList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他" :label-width="labelwidth" v-if="form.familyHistory.monter.value.includes('15')">
                <el-input v-model="form.familyHistory.monter.remark">
                </el-input>
            </el-form-item>
            <el-form-item label="兄弟姐妹" :label-width="labelwidth">
                <el-select v-model="form.familyHistory.brothers.value" multiple placeholder="请选择" style="width:100%"
                           clearable>
                    <el-option :label="items.label" :value="items.value" v-for="(items,index) in dataList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他" :label-width="labelwidth" v-if="form.familyHistory.brothers.value.includes('15')">
                <el-input v-model="form.familyHistory.brothers.remark">
                </el-input>
            </el-form-item>
            <el-form-item label="子女" :label-width="labelwidth">
                <el-select v-model="form.familyHistory.children.value" multiple placeholder="请选择" style="width:100%"
                           clearable>
                    <el-option :label="items.label" :value="items.value" v-for="(items,index) in dataList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他" :label-width="labelwidth" v-if="form.familyHistory.children.value.includes('15')">
                <el-input v-model="form.familyHistory.children.remark">
                </el-input>
            </el-form-item>
            <el-form-item label="直系亲属" :label-width="labelwidth">
                <el-select v-model="form.familyHistory.familyDiseases.value" multiple placeholder="请选择" style="width:100%"
                           clearable>
                    <el-option :label="items.label" :value="items.value" v-for="(items,index) in dataList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他" :label-width="labelwidth" v-if="form.familyHistory.familyDiseases.value.includes('15')">
                <el-input v-model="form.familyHistory.familyDiseases.remark">
                </el-input>
            </el-form-item>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'familyHistoryForm',
        props: {
            labelwidth: {
                type: String,
                default: "120px"
            },
            form: {},
            btnBgColor: {
                type: String,
                default: "#9089fa"
            },
            btnColor: {
                type: String,
                default: "#ffffff"
            },

        },
        data() {
            return {
                loading: false,
                dataList:[
                    {label:"高血压",value:"1"},
                    {label:"糖尿病",value:"2"},
                    {label:"冠心病",value:"3"},
                    {label:"慢性阻塞性肺疾病",value:"4"},
                    {label:"恶性肿瘤",value:"5"},
                    {label:"脑辛中",value:"6"},
                    {label:"严重精神障碍",value:"7"},
                    {label:"结核病",value:"8"},
                    {label:"肝炎",value:"9"},
                    {label:"先天畸形",value:"10"},
                    {label:"心脏病",value:"11"},
                    {label:"脑梗",value:"12"},
                    {label:"血脂异常",value:"13"},
                    {label:"哮喘",value:"14"},
                    {label:"其它",value:"15"},
                ]
            }
        },
        methods: {
            handelIllness(v, type, items) {
                if (v == 1 && this.form.pastHistory[type].list.length == 0) {
                    this.form.pastHistory[type].list.push(items)
                }
            },
            handeAdd(type, items) {
                this.form.pastHistory[type].list.push(items)
            },
            handelDel(index, type) {
                this.form.pastHistory[type].list.splice(index, 1)
            }
        },
    };
</script>

<style scoped>
    /deep/ .el-radio-button__inner, .el-radio-group {
        display: inline-block;
        vertical-align: middle;
    }

    /deep/ .el-input-group__append, .el-input-group__prepend {
        border: 1px solid #ffffff;
        border-left: 1px solid #f7f7f7;
    }
</style>
