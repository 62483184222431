import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    componentAState: '初始状态 A'
  },
  mutations: {
    updateComponentAState(state, newState) {
      state.componentAState = newState;
    }
  },
  actions: {
    setComponentAState({ commit }, newState) {
      commit('updateComponentAState', newState);
    }
  },
  getters: {
    getComponentAState: state => state.componentAState
  }
});

export default store;