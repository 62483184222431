const data = {
    tab1form: [
        {
            "name": "",
            "type": "title",
            "title": "基本信息",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "patientInfo",
            two:"name",
            "type": "input",
            "title": "姓名",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"sex",
            "type": "radio",
            "title": "性别",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 10,
            disabled: false,
            options:[
                {'label':"男","value":"1"},
                {'label':"女","value":"2"},
            ]
        },
        {
            "name": "patientInfo",
            two:"age",
            "type": "input",
            "title": "年龄",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            "type": "input",
            "title": "民族",
            two:"nation",
            span: 12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"idCard",
            "type": "input",
            "title": "身份证",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: true
        },
        {
            "name": "patientInfo",
            two:"birthday",
            "type": "date",
            "title": "出生日期",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"mobile",
            "type": "input",
            "title": "联系方式",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"permanent",
            "type": "select",
            "title": "常住户籍",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false,
            key:"value",
            label:"label",
            options:[
                {'label':"户籍","value":"1","id":1},
                {'label':"非户籍","key":"2","id":2},
            ]
        },
        {
            "name": "patientInfo",
            "type": "input",
            "title": "紧急联系人",
            two:"guardian",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"ecmobile",
            "type": "input",
            "title": "紧急联系电话",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"educationLevel",
            "type": "select",
            "title": "文化程度",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false,
            key:"value",
            label:"label",
            options:[
                {'label':"研究生及以上","value":"1","id":1},
                {'label':"大学本科","value":"2","id":2},
                {'label':"大学专科和专科学校","value":"3","id":3},
                {'label':"中等专业学校","value":"4","id":4},
                {'label':"技工学校","value":"5","id":5},
                {'label':"高中","value":"6","id":6},
                {'label':"初中","value":"7","id":7},
                {'label':"小学","value":"8","id":8},
                {'label':"文盲或半文盲","value":"9","id":9},
                {'label':"不详","value":"10","id":10},
            ]
        },
        {
            "name": "patientInfo",
            two:"professional",
            "type": "select",
            "title": "职业",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false,
            key:"value",
            label:"label",
            options:[
                {'label':"专业技术人员","value":"1","id":1},
                {'label':"职员及管理人员","value":"2","id":2},
                {'label':"企业领导","value":"3","id":3},
                {'label':"学生或儿童","value":"4","id":4},
                {'label':"商业及服务业人员","value":"5","id":5},
                {'label':"离退休人员","value":"6","id":6},
                {'label':"自由职业或无固定职业人员","value":"7","id":7},
                {'label':"其它职业","value":"8","id":8},
            ]
        },
        {
            "name": "",
            "type": "title",
            "title": "支付方式",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "patientInfo",
            two:"payType",
            "type": "multiple",
            "title": "支付方式",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false,
            key:"value",
            label:"label",
            options:[
                {'label':"城镇职工基本医疗保险","value":"1","id":1},
                {'label':"城镇居民基本医疗保险","value":"2","id":2},
                {'label':"新型农村合作医疗","value":"3","id":3},
                {'label':"贫困救助","value":"4","id":4},
                {'label':"商业医疗保险","value":"5","id":5},
                {'label':"全公费","value":"6","id":6},
                {'label':"全自费","value":"7","id":7},
                {'label':"其他","value":"8","id":8},
            ]
        },
        {
            "name": "",
            "type": "title",
            "title": "单位信息",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "patientInfo",
            two:"companyName",
            "type": "input",
            "title": "单位",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"companyLevel",
            "type": "input",
            "title": "级别",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"companyJobTitle",
            "type": "input",
            "title": "职称",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"salaryNumber",
            "type": "input",
            "title": "工号",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"companyJob",
            "type": "input",
            "title": "岗位",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "patientInfo",
            two:"workType",
            "type": "input",
            "title": "工种",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
    ],
    tab2form: [
        {
            "name": "",
            "type": "title",
            "title": "健康情况",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name":"healthInfo",
            "two": "height",
            "type": "input",
            "title": "身高",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            slots:"cm",
            disabled: false
        },
        {
            "name":"healthInfo",
            "two": "weight",
            "type": "input",
            "title": "体重",
            slots:"kg",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"healthInfo",
            "two": "BMI",
            "type": "input",
            "title": "BMI",
            span: 12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name":"healthInfo",
            "two": "blood",
            "type": "input",
            "title": "血型",
            span: 12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name":"healthInfo",
            "two": "waist",
            "type": "input",
            "title": "腹围",
            slots:"cm",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "既往史",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name":"healthInfo",
            "two": "jib",
            "type": "input",
            "title": "疾病",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"healthInfo",
            "two": "shoushu",
            "type": "input",
            "title": "手术",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"healthInfo",
            "two": "waishang",
            "type": "input",
            "title": "外伤",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"healthInfo",
            "two": "shuxue",
            "type": "input",
            "title": "输血",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "其它",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name":"healthInfo",
            "two": "ycbs",
            "type": "input",
            "title": "遗传病史",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
            disabled: false
        },
    ],
    tab3form: [
        {
            "name": "",
            "type": "title",
            "title": "家庭情况",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name":"patientInfo",
            "two": "marital",
            "type": "input",
            "title": "婚姻状况",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"patientInfo",
            "two": "syzk",
            "type": "input",
            "title": "生育状况",
            span: 12,
            required: false,
            placeholder: "请输入",
            value: "",
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "家族史",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name":"JTXX",
            "two": "fuq",
            "type": "input",
            "title": "父母",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"JTXX",
            "two": "muq",
            "type": "input",
            "title": "母亲",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"JTXX",
            "two": "xdjm",
            "type": "input",
            "title": "兄弟姐妹",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"JTXX",
            "two": "zinv",
            "type": "input",
            "title": "子女",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name":"JTXX",
            "two": "zxqs",
            "type": "input",
            "title": "直系亲属",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
    ],
}
export default data
