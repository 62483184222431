<template>
    <el-select :value="value"  :collapse-tags="collapse" filterable @input="$emit('input', $event)"
        placeholder="选择专业" :size="size" style="width:100%" clearable remote reserve-keyword :remote-method="remoteMethod"
        :multiple-limit="0">
        <el-option v-for="item in filteredOptions" :key="item.name" :label="item.name" :value="item.name">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'selectExpertMajor',
    props: {
        value: {
            type: String,
            default: ''
        },
        collapse: {
            default: true
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            options: [],
            filteredOptions: []
        }
    },
    methods: {
        remoteMethod(query) {
            if (query) {
                this.filteredOptions = this.options.filter((option) =>
                    option.name.toLowerCase().includes(query.toLowerCase())
                )
            } else {
                this.filteredOptions = this.options
            }
        },
        async getData() {
            const resp = await this.$http.get('/expert/dict/major?page=1&pageSize=200')
            this.options = resp.data
            this.filteredOptions = resp.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped></style>
