<template>
        <div slot="header" class="clearfix">
            <v-chart ref="chart" class="chart" :option="option" v-loading="loading" />
        </div>
</template>

<script>
export default {
    name: 'xiaowuChart',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '小屋测量',
                    left: 'center'
                },
                grid: {
                    left: 0,
                    right: 0
                },
                // 设置环形图的大小和位置
                graphic: [
                    {
                        type: 'text',
                        left: 'center',
                        top: '42%',
                        style: {
                            text: '97',
                            font: 'bold 30px Arial',
                            fill: '#333'
                        }
                    },
                    {
                        type: 'text',
                        left: 'center',
                        top: '60%',
                        style: {
                            text: '完成度',
                            font: 'bold 12px Arial',
                            fill: '#333'
                        }
                    }
                ],
                series: [{
                    type: 'pie',
                    radius: ['60%', '50%'],
                    center: ['50%', '50%'],
                    data: [{
                        value: 75, //已完成的数值
                        itemStyle: {
                            color: '#1890ff' //已完成部分的颜色
                        }
                    },
                    {
                        value: 25, //未完成的数值
                        itemStyle: {
                            color: '#f0f2f5'
                        },
                        emphasis: {
                            color: '#f0f2f5'
                        }
                    }
                    ],
                    label: {
                        show: false
                    }
                }]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/components/charts/dashboard/wanchengdu')
            let todo = 200//resp.data.todo
            let finish = 194//resp.data.finish
            this.option.graphic[0].style.text = `${(finish / (todo + finish) * 100).toFixed(2)}%`
            this.option.series[0].data[0].value = finish
            this.option.series[0].data[1].value = todo
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        // this.getData().then(() => {
        //     this.$nextTick(() => {
        //         this.$refs.chart.resize()
        //     })
        //     window.addEventListener('resize', () => {
        //         if (this.$refs && this.$refs.chart) {
        //             this.$refs.chart.resize()
        //         }
        //     })
        // })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 200px;
}
</style>
