<template>
    <div>
        <p>{{ componentAState }}</p>
        <button @click="updateState">更新状态 A</button>
    </div>
</template>

<script>
import store from './store.js';

export default {
    name: 'ComponentA',
    computed: {
        componentAState() {
            return store.getters.getComponentAState;
        }
    },
    methods: {
        updateState() {
            store.dispatch('setComponentAState', '新的状态 A');
        }
    }
};
</script>