<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>异常因数分布</span>
            <div style="float:right">
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
                <el-popover placement="bottom" title="过滤条件" width="350" trigger="click" v-model="visible">
                    <el-form ref="form" :model="form" label-width="90px" size="mini">
                        <el-form-item label="评估日期">
                            <el-date-picker v-model="form.year" type="year" style="width:260px" value-format="yyyy"
                                            placeholder="选择年">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">关闭</el-button>
                        <el-button type="primary" size="mini" @click="getData">确定</el-button>
                    </div>
                    <el-button slot="reference" style="padding: 3px 0;margin-left:10px;" type="text"
                               icon="el-icon-setting"></el-button>
                </el-popover>
            </div>
        </div>
        <v-chart ref="ycyschart" class="chart" :option="option" v-loading="loading" />
    </el-card>
</template>

<script>
export default {
    name: 'ycysPieChart',
    props: {
        form: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            loading: false,
            visible: false,
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    top: 'middle'
                },
                series: [
                    {
                        name: '异常因数占比',
                        type: 'pie',
                        radius:'55%',
                        center: ['50%', '50%'],
                        data: [
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    watch: {
        "form.companyName": {
            // eslint-disable-next-line no-unused-vars
            handler(newValue, oldValue) {
                this.getData()
            },
            deep: true,
            immediate: true
        }
    },
    beforeDestroy() {
        this.destroyResizeEvent();
    },
    methods: {
        initResizeEvent() {
            window.addEventListener('resize', this.resizeChart);
        },
        // 移除窗口大小变化事件监听
        destroyResizeEvent() {
            window.removeEventListener('resize', this.resizeChart);
        },
        // 调整图表尺寸的方法
        resizeChart() {
            if (this.$refs.ycyschart) {
                this.$refs.ycyschart.resize();
            }
        },
        async getData() {
            this.loading = true;
            try {
                const resp = await this.$http.get('/company/dashboard/fxys', { params: this.form });
                // 假设resp.data.data是你需要的数据数组
                const seriesData = resp.data.data;

                // 更新系列数据
                this.option.series[0].data = seriesData;

                // 更新图例数据
                this.option.legend.data = seriesData.map(item => item.name);

                // 动态更新legend.formatter以访问最新的seriesData
                this.option.legend.formatter = (name) => {
                    const item = seriesData.find(item => item.name === name);
                    if (item) {
                        return `${name}: ${item.value}`; // 返回自定义的图例文本
                    }
                    return name;
                };

                // 使用true参数确保不合并，而是重置选项
                this.$refs['ycyschart'].setOption(this.option, true);
            } catch (error) {
                console.error("获取数据失败", error);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initResizeEvent();
            this.resizeChart();
        });
        this.getData()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 350px;
}
</style>
