<template>
    <el-select :value="value" value-key="id"  :collapse-tags="collapse" filterable @input="$emit('input', $event)" placeholder="选择联系人" @change="filterPhone"
        :size="size" style="width:100%" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.name" />
    </el-select>
</template>

<script>
export default {
    name: 'selectUser',
    props: {
        value: {
            type: String,
            default: ''
        },
        size: {
            default: 'small'
        },
        collapse: {
            default: true
        }
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        async getData() {
            const resp = await this.$http.get('/user?page=1&pageSize=500')
            this.options = resp.data.rows
        },
        filterPhone(value){
            const selectedOption = this.options.find(option => option.name === value);
            if(selectedOption){
                this.$emit('getUserPhone', selectedOption.data.phone)
            }else {
                this.$emit('getUserPhone', "")
            }
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped></style>
