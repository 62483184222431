<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'app',
    created() {
        document.title = '健康管理服务平台'
    }
}
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
}

.el-container {
    height: 100%;
    overflow: hidden;
}

.el-message {
    min-width: 100px !important;
}

/* 覆盖Element UI Tooltip的宽度 */
.el-tooltip__popper {
    max-width: 300px !important; /* 设置你想要的宽度 */
}
.el-tooltip__popper.is-dark{
    background: #4f9fe7 !important;
}
</style>
