<template>
    <div style="height: 500px;overflow-y: auto;">
        <div style="font-weight: 700;padding:10px">健康数据</div>
        <el-row>
            <el-col :span="12">
                <el-form-item label="身高" :label-width="labelwidth">
                    <el-input v-model="form.healthInfo.height">
                        <template slot="append">cm</template>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="体重" :label-width="labelwidth">
                    <el-input v-model="form.healthInfo.weight">
                        <template slot="append">kg</template>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="BMI" :label-width="labelwidth">
                    <el-input v-model="form.healthInfo.BMI">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="血型" :label-width="labelwidth">
                    <el-select v-model="form.healthInfo.blood" placeholder="请选择" style="width:100%" clearable>
                        <el-option label="A型" value="1"></el-option>
                        <el-option label="B型" value="2"></el-option>
                        <el-option label="O型" value="3"></el-option>
                        <el-option label="AB型" value="4"></el-option>
                        <el-option label="不详或其它" value="5"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="腹围" :label-width="labelwidth">
                    <el-input v-model="form.healthInfo.waist">
                    </el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="font-weight: 700;padding:10px">既往史</div>
        <el-row>
            <el-form-item label="疾病" :label-width="labelwidth">
                <el-radio-group v-model="form.pastHistory.illness.has"
                                @change="handelIllness($event, 'illness',{time:'',illnessName:''})">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">有</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-row v-if="form.pastHistory.illness.has == 1">
                <el-form-item :label-width="labelwidth" v-for="(items, index) in form.pastHistory.illness.list"
                              :key="index">
                    <el-row :gutter="10">
                        <el-col :span="10">
                            <el-date-picker
                                    v-model="items.time"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="items.illnessName=='15'?4:6" >
                            <el-select v-model="items.illnessName" placeholder="请选择" style="width:100%" clearable>
                                <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6" v-if="items.illnessName=='15'">
                            <el-input v-model="items.remark" placeholder="请输入疾病名称"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click.prevent="handeAdd('illness',{time:'',illnessName:''})"
                                       v-if="index==0"
                                       icon="el-icon-circle-plus-outline" type="text"
                                       style="font-size: 20px"></el-button>
                            <el-button @click.prevent="handelDel(index,'illness')" icon="el-icon-remove-outline"
                                       v-if="index!=0"
                                       type="text" style="font-size: 20px"></el-button>
                        </el-col>
                    </el-row>

                </el-form-item>
            </el-row>
            <el-form-item label="手术" :label-width="labelwidth">
                <el-radio-group v-model="form.pastHistory.surgery.has"
                                @change="handelIllness($event, 'surgery',{time:'',surgeryName:''})">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">有</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-row v-if="form.pastHistory.surgery.has == 1">
                <el-form-item :label-width="labelwidth" v-for="(items, index) in form.pastHistory.surgery.list"
                              :key="index">
                    <el-row :gutter="10">
                        <el-col :span="10">
                            <el-date-picker
                                    v-model="items.time"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="10">
                            <el-input v-model="items.surgeryName" placeholder="请输入手术名称"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click.prevent="handeAdd('surgery',{time:'',surgeryName:''})"
                                       v-if="index==0"
                                       icon="el-icon-circle-plus-outline" type="text"
                                       style="font-size: 20px"></el-button>
                            <el-button @click.prevent="handelDel(index,'surgery')" icon="el-icon-remove-outline"
                                       v-if="index!=0"
                                       type="text" style="font-size: 20px"></el-button>
                        </el-col>
                    </el-row>

                </el-form-item>
            </el-row>
            <el-form-item label="外伤" :label-width="labelwidth">
                <el-radio-group v-model="form.pastHistory.trauma.has"
                                @change="handelIllness($event, 'trauma',{time:'',traumaName:''})">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">有</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-row v-if="form.pastHistory.trauma.has == 1">
                <el-form-item :label-width="labelwidth" v-for="(items, index) in form.pastHistory.trauma.list"
                              :key="index">
                    <el-row :gutter="10">
                        <el-col :span="10">
                            <el-date-picker
                                    v-model="items.time"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="10">
                            <el-input v-model="items.traumaName" placeholder="请输入外伤描述"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click.prevent="handeAdd('trauma',{time:'',traumaName:''})"
                                       v-if="index==0"
                                       icon="el-icon-circle-plus-outline" type="text"
                                       style="font-size: 20px"></el-button>
                            <el-button @click.prevent="handelDel(index,'trauma')" icon="el-icon-remove-outline"
                                       v-if="index!=0"
                                       type="text" style="font-size: 20px"></el-button>
                        </el-col>
                    </el-row>

                </el-form-item>
            </el-row>
            <el-form-item label="输血" :label-width="labelwidth">
                <el-radio-group v-model="form.pastHistory.transfusion.has"
                                @change="handelIllness($event, 'transfusion',{time:'',transfusionName:''})">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">有</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-row v-if="form.pastHistory.transfusion.has == 1">
                <el-form-item :label-width="labelwidth" v-for="(items, index) in form.pastHistory.transfusion.list"
                              :key="index">
                    <el-row :gutter="10">
                        <el-col :span="10">
                            <el-date-picker
                                    v-model="items.time"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="10">
                            <el-input v-model="items.transfusionName" placeholder="请输入输血原因"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click.prevent="handeAdd('transfusion',{time:'',transfusionName:''})"
                                       v-if="index==0"
                                       icon="el-icon-circle-plus-outline" type="text"
                                       style="font-size: 20px"></el-button>
                            <el-button @click.prevent="handelDel(index,'transfusion')" icon="el-icon-remove-outline"
                                       v-if="index!=0"
                                       type="text" style="font-size: 20px"></el-button>
                        </el-col>
                    </el-row>

                </el-form-item>
            </el-row>
        </el-row>
        <div style="font-weight: 700;padding:10px">其他</div>
        <el-row>
            <el-form-item label="疾病" :label-width="labelwidth">
                <el-radio-group v-model="form.other.heredity">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">有</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="" :label-width="labelwidth" v-if="form.other.heredity==1">
                <el-input v-model="form.other.heredityDetail">
                </el-input>
            </el-form-item>
            <el-form-item label="现病史" :label-width="labelwidth">
                <el-input v-model="form.other.illnessHostory" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="病情描述" :label-width="labelwidth">
                <el-input v-model="form.other.frist" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="备注" :label-width="labelwidth">
                <el-input v-model="form.other.all" type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}">
                </el-input>
            </el-form-item>
            <el-form-item label="残疾情况" :label-width="labelwidth">
                <el-select v-model="form.other.disability.value" multiple placeholder="请选择" style="width:100%"
                           clearable>
                    <el-option label="肢体残疾" value="1"></el-option>
                    <el-option label="智力残疾" value="2"></el-option>
                    <el-option label="精神残疾" value="3"></el-option>
                    <el-option label="其他残疾" value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="其他残疾" :label-width="labelwidth" v-if="form.other.disability.value.includes('4')">
                <el-input v-model="form.other.disability.remark">
                </el-input>
            </el-form-item>
            <el-form-item label="药物过敏史" :label-width="labelwidth">
                <el-select v-model="form.other.drugIrritability.value" multiple placeholder="请选择" style="width:100%"
                           clearable>
                    <el-option label="青每素" value="1"></el-option>
                    <el-option label="碣胺类" value="2"></el-option>
                    <el-option label="链霉素" value="3"></el-option>
                    <el-option label="其它" value="4"></el-option>
                    <el-option label="头孢类" value="5"></el-option>
                    <el-option label="维生素B1" value="6"></el-option>
                    <el-option label="地卡因" value="7"></el-option>
                    <el-option label="破伤风抗霉素" value="8"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="药物过敏史其它" :label-width="labelwidth"
                          v-if="form.other.drugIrritability.value.includes('4')">
                <el-input v-model="form.other.drugIrritability.remark">
                </el-input>
            </el-form-item>
            <el-form-item label="食物过敏史" :label-width="labelwidth">
                <el-input v-model="form.other.foodsIrritability">
                </el-input>
            </el-form-item>
            <el-form-item label="暴露史" :label-width="labelwidth">
                <el-select v-model="form.other.expose" multiple placeholder="请选择" style="width:100%" clearable>
                    <el-option label="化学品" value="1"></el-option>
                    <el-option label="毒物" value="2"></el-option>
                    <el-option label="射线" value="3"></el-option>
                </el-select>
            </el-form-item>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'healthInfoForm',
        props: {
            labelwidth: {
                type: String,
                default: "120px"
            },
            form: {},
            btnBgColor: {
                type: String,
                default: "#9089fa"
            },
            btnColor: {
                type: String,
                default: "#ffffff"
            },

        },
        data() {
            return {
                loading: false,
                dataList:[
                    {label:"高血压",value:"1"},
                    {label:"糖尿病",value:"2"},
                    {label:"冠心病",value:"3"},
                    {label:"慢性阻塞性肺疾病",value:"4"},
                    {label:"严重精神障碍",value:"5"},
                    {label:"结核病",value:"6"},
                    {label:"肝炎",value:"7"},
                    {label:"心脏病",value:"8"},
                    {label:"过敏性疾病",value:"9"},
                    {label:"哮喘",value:"10"},
                    {label:"癫痫",value:"11"},
                    {label:"脑梗",value:"12"},
                    {label:"血脂异常",value:"13"},
                    {label:"脑辛中",value:"14"},
                    {label:"其它",value:"15"}
                ],
            }
        },
        methods: {
            handelIllness(v, type, items) {
                if (v == 1 && this.form.pastHistory[type].list.length == 0) {
                    this.form.pastHistory[type].list.push(items)
                }
            },
            handeAdd(type, items) {
                this.form.pastHistory[type].list.push(items)
            },
            handelDel(index, type) {
                this.form.pastHistory[type].list.splice(index, 1)
            }
        },
    };
</script>

<style scoped>
    /deep/ .el-radio-button__inner, .el-radio-group {
        display: inline-block;
        vertical-align: middle;
    }

    /deep/ .el-input-group__append, .el-input-group__prepend {
        border: 1px solid #ffffff;
        border-left: 1px solid #f7f7f7;
    }
</style>
