<template>
    <el-dialog title="添加/修改基础信息" :visible="visible" width="50%" @close="close" :close-on-click-modal="false" top="10vh">
        <el-row>
            <el-form ref="ruleForm" :model="form" label-width="125px" size="small">
                <el-tabs v-model="activetab">
                    <el-tab-pane label="个人信息" name="1">
                        <my-form :formData="tab1data" :form="form" labelwidth="125px"></my-form>
                    </el-tab-pane>
                    <el-tab-pane label="健康信息" name="2">
                        <health-info-form :form="form" labelwidth="110px" ></health-info-form>
                    </el-tab-pane>
                    <el-tab-pane label="家庭信息" name="3">
                        <family-history-form :form="form" labelwidth="80px"></family-history-form>
                    </el-tab-pane>
                    <el-tab-pane label="生活信息" name="4">
                        <life-info-form :form="form" labelwidth="120px"></life-info-form>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">提 交
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
    import MyForm from "../form/myform";
    import healthInfoForm from "./healthInfoForm";
    import familyHistoryForm from "./familyHistoryForm";
    import lifeInfoForm from "./lifeInfoForm";
    import data from "./data"

    export default {
        name: 'InfoForm',
        components: {
            MyForm,
            healthInfoForm,
            familyHistoryForm,
            lifeInfoForm
        },
        props: {
            form: {
                default: {
                    patientInfo:{
                        payType:[]
                    }
                }
            },
            visible: {
                default: false
            },
            type: {
                default:"edit"
            }
        },
        data() {
            return {
                activetab: "1",
                loading: false,
                tab1data: data.tab1form,
                tab2data: data.tab2form,
                tab3data: data.tab3form,
            }
        },
        methods: {
            async formSubmit() {
                console.log(this.form)
                const valid = await this.$refs['ruleForm'].validate().then(res => res).catch(() => {
                    return false
                });
                if (!valid) return
                let dataForm =JSON.stringify(this.form);
                dataForm = JSON.parse(dataForm)
                dataForm.type = this.type
                const resp = await this.$http.post(`/phr/form`, dataForm)
                this.$message[resp.data.status]("提交成功！")
                if (resp.data.status == 'success') {
                    this.$emit('close')
                    this.$emit('update')
                }
            },
            close() {
                this.$emit("close")
            },
        },
        mounted() {

        }
    };
</script>

<style scoped>
    /deep/ .el-input__inner {
        -webkit-appearance: none;
        background: #f7f7f7;
        border: 1px solid #f7f7f7;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 13px;
    }

    /deep/ .el-textarea__inner {
        -webkit-appearance: none;
        background: #f7f7f7;
        border: 1px solid #f7f7f7;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 13px;
    }


</style>
