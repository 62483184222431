<template>
    <div>
        <div style="position: absolute;right: 10px;top: 5px;cursor: pointer;z-index: 999">
            <el-button type="text" icon="el-icon-edit" size="small" @click="dialogVisible = true" v-if="isEdit">编辑</el-button>
        </div>
        <el-tabs v-model="activeValue" @tab-click="handleClick" style="padding-left:20px;">
            <el-tab-pane label="个人信息" name="1">
                <div :style="'height:calc(100vh - ' + height + ');overflow-y: auto;'">
                    <el-descriptions title="个人信息" :column="2">
                        <el-descriptions-item label="姓名">{{ form.patientInfo ? form.patientInfo.name :
                '' }}</el-descriptions-item>
                        <el-descriptions-item label="性别">
                            <span v-if="form.patientInfo && form.patientInfo.sex == '2'" class="value"> 女 </span>
                            <span v-if="form.patientInfo && form.patientInfo.sex == '1'" class="value"> 男 </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="年龄">{{ form.patientInfo ? form.patientInfo.age : '' }}</el-descriptions-item>
                        <el-descriptions-item label="民族">{{ form.patientInfo ? form.patientInfo.nation : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="身份证">{{ form.patientInfo ? form.patientInfo.idCard : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="出生日期">{{ form.patientInfo ? form.patientInfo.birthday : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="联系方式">{{ form.patientInfo ? form.patientInfo.mobile : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="常住户籍">
                            <span v-if="form.patientInfo ? form.patientInfo.permanent == '2':false" class="value"> 非户籍 </span>
                            <span v-if="form.patientInfo ? form.patientInfo.permanent == '1' :false" class="value"> 户籍 </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="紧急联系人">{{ form.patientInfo ? form.patientInfo.guardian : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="紧急联系电话">{{ form.patientInfo ? form.patientInfo.ecmobile : '无' }}</el-descriptions-item>
                        <el-descriptions-item
                            label="文化程度">{{ form.patientInfo ? form.patientInfo.educationLevel: ''|educationLevelFormatter}}</el-descriptions-item>
                        <el-descriptions-item label="职业">{{ form.patientInfo ? form.patientInfo.professional:''|professionalFormatter}}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="支付方式" :column="1">
                        <el-descriptions-item
                            label="医疗费用支付方式">{{ form.patientInfo ? form.patientInfo.payType : '' | payTypeFormatter }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="单位信息" :column="2">
                        <el-descriptions-item label="单位">{{ form.patientInfo ? form.patientInfo.companyName : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="级别">{{ form.patientInfo ? form.patientInfo.companyLevel : '无' }}</el-descriptions-item>
                        <el-descriptions-item
                            label="职称">{{ form.patientInfo ? form.patientInfo.companyJobTitle : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="工号">{{ form.patientInfo ? form.patientInfo.salaryNumber : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="岗位">{{ form.patientInfo ? form.patientInfo.companyJob : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="工种">{{ form.patientInfo ? form.patientInfo.workType : '无' }}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-tab-pane>
            <el-tab-pane label="健康信息" name="2">
                <div :style="'height:calc(100vh - ' + height + ');overflow-y: auto;'">
                    <el-descriptions title="健康情况" :column="2">
                        <el-descriptions-item label="身高">{{ form.healthInfo ? form.healthInfo.height : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="体重">{{ form.healthInfo ? form.healthInfo.weight : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="BMI">{{ form.healthInfo ? form.healthInfo.BMI : '无' }}</el-descriptions-item>
                        <el-descriptions-item
                            label="血型">{{ form.healthInfo ? form.healthInfo.blood : '' | bloodFormatter }}</el-descriptions-item>
                        <el-descriptions-item label="腹围">{{ form.healthInfo ? form.healthInfo.waist : '无' }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="既往史" :column="1">
                        <el-descriptions-item
                            label="疾病">{{ form.pastHistory ? form.pastHistory.illness :'' | illnessFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="手术">{{ form.pastHistory ? form.pastHistory.surgery : '' | surgeryFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="外伤">{{ form.pastHistory ? form.pastHistory.trauma : '' | traumaFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="输血">{{ form.pastHistory ? form.pastHistory.transfusion : '' | transfusionFormatter }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="其它" :column="1">
                        <el-descriptions-item label="疾病" v-if="form.other && form.other.heredity">{{form.other ? form.other.heredityDetail : '无'}}</el-descriptions-item>
                        <el-descriptions-item label="疾病" v-else>无</el-descriptions-item>
                        <el-descriptions-item label="现病史">{{ form.other ? form.other.illnessHostory : '' }}</el-descriptions-item>
                        <el-descriptions-item label="病情描述">{{ form.other ? form.other.frist : '' }}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{ form.other ? form.other.all : '' }}</el-descriptions-item>
                        <el-descriptions-item label="残疾情况">{{ form.other ? form.other.disability : ''|disabilityFormatter }}</el-descriptions-item>
                        <el-descriptions-item label="药物过敏史">{{ form.other ? form.other.drugIrritability : ''|drugIrritabilityFormatter }}</el-descriptions-item>
                        <el-descriptions-item label="食物过敏史">{{ form.other ? form.other.foodsIrritability : '' }}</el-descriptions-item>
                        <el-descriptions-item label="暴露史">{{ form.other ? form.other.expose : ''|exposeFormatter }}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-tab-pane>
            <el-tab-pane label="家庭信息" name="3">
                <div :style="'height:calc(100vh - ' + height + ');overflow-y: auto;'">
                    <el-descriptions title="家庭情况" :column="2">
                        <el-descriptions-item
                            label="婚姻状况">{{ form.pastHistory ? form.patientInfo.marital :'' | maritalFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="生育状况">{{ form.pastHistory ? form.patientInfo.fertility : '' | syztFormatter }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="家族史" :column="1">
                        <el-descriptions-item
                            label="父亲">{{ form.familyHistory ? form.familyHistory.fater : '' | familyHistoryFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="母亲">{{ form.familyHistory ? form.familyHistory.monter : '' | familyHistoryFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="兄弟姐妹">{{ form.familyHistory ? form.familyHistory.brothers : '' | familyHistoryFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="子女">{{ form.familyHistory ? form.familyHistory.children : '' | familyHistoryFormatter }}</el-descriptions-item>
                        <el-descriptions-item
                            label="直系亲属">{{ form.familyHistory ? form.familyHistory.familyDiseases : '' | familyHistoryFormatter }}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-tab-pane>
            <el-tab-pane label="生活信息" name="4">
                <div :style="'height:calc(100vh - ' + height + ');overflow-y: auto;'">
                    <el-descriptions title="生活信息" :column="2">
                        <el-descriptions-item label="饮食习惯">{{ form.lifeInfo ? form.lifeInfo.foods : "无" }}</el-descriptions-item>
                        <el-descriptions-item label="睡眠情况">{{ form.lifeInfo ? form.lifeInfo.sleep : '' }}</el-descriptions-item>
                        <el-descriptions-item label="吸烟史">{{ form.lifeInfo ? form.lifeInfo.smoke : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="饮酒史">{{ form.lifeInfo ? form.lifeInfo.drink : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="生活作息">{{ form.lifeInfo ? form.lifeInfo.daily : '无' }}</el-descriptions-item>
                        <el-descriptions-item label="日常生活方式">{{ form.lifeInfo ? form.lifeInfo.style : '无' }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="生活环境" :column="1">
                        <el-descriptions-item label="厨房排风设施">{{ form.environment ? form.environment.exhaust : '' | exhaustFormatter}}</el-descriptions-item>
                        <el-descriptions-item label="燃料类型">{{ form.environment ? form.environment.energy : ''| energyFormatter }}</el-descriptions-item>
                        <el-descriptions-item label="饮水">{{ form.environment ? form.environment.water : '' | waterFormatter}}</el-descriptions-item>
                        <el-descriptions-item label="厕所">{{ form.environment ? form.environment.toilet : ''| toiletFormatter }}</el-descriptions-item>
                        <el-descriptions-item label="禽兽栏">{{ form.environment ? form.environment.acorral : ''| acorralFormatter}}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-tab-pane>
        </el-tabs>
        <data-form :visible="dialogVisible" :form="form" @close="dialogClose" :formData="formData"></data-form>
    </div>
</template>

<script>
import tabdata from "./data";
import DataForm from "./InfoFrom"


export default {
    name: "userInfo",
    props: {
        form: {
            default: {
            }
        },
        height: {
            default: "410px"
        },
        isEdit: {
            default: true
        },
        type:{
            default: "edit"
        }
    },
    components: {
        DataForm
    },
    data() {
        return {
            activeValue: "1",
            dialogVisible: false,
            formData: tabdata.tab1form
        }
    },
    filters: {
        familyHistoryFormatter: function (item) {
            if (!item) {
                return ""
            }
            var value = item.value
            if (typeof value === "string") {
                value = JSON.parse(value || "[]")
            }
            const items = [
                { label: "高血压", value: "1" },
                { label: "糖尿病", value: "2" },
                { label: "冠心病", value: "3" },
                { label: "慢性阻塞性肺疾病", value: "4" },
                { label: "恶性肿瘤", value: "5" },
                { label: "脑辛中", value: "6" },
                { label: "严重精神障碍", value: "7" },
                { label: "结核病", value: "8" },
                { label: "肝炎", value: "9" },
                { label: "先天畸形", value: "10" },
                { label: "心脏病", value: "11" },
                { label: "脑梗", value: "12" },
                { label: "血脂异常", value: "13" },
                { label: "哮喘", value: "14" },
                { label: "其它", value: "15" },
            ];
            const labels = value.map(value => {
                const item = items.find(item => item.value === value);
                return item ? item.label : null;
            });
            if(item.remark){
                labels.push("其它：" + item.remark)
            }
            return labels.join("、") || "无"
        },
        payTypeFormatter: function (value) {
            if (!value) {
                return ""
            }
            if (typeof value === "string") {
                value = JSON.parse(value || "[]")
            }
            const items = [
                { 'label': "城镇职工基本医疗保险", "value": "1", "id": 1 },
                { 'label': "城镇居民基本医疗保险", "value": "2", "id": 2 },
                { 'label': "新型农村合作医疗", "value": "3", "id": 3 },
                { 'label': "贫困救助", "value": "4", "id": 4 },
                { 'label': "商业医疗保险", "value": "5", "id": 5 },
                { 'label': "全公费", "value": "6", "id": 6 },
                { 'label': "全自费", "value": "7", "id": 7 },
                { 'label': "其他", "value": "8", "id": 8 },
            ];
            const labels = value.map(value => {
                const item = items.find(item => item.value === value);
                return item ? item.label : null;
            });
            return labels.join("、") || "暂无"
        },
        educationLevelFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                {'label':"研究生及以上","value":"1","id":1},
                {'label':"大学本科","value":"2","id":2},
                {'label':"大学专科和专科学校","value":"3","id":3},
                {'label':"中等专业学校","value":"4","id":4},
                {'label':"技工学校","value":"5","id":5},
                {'label':"高中","value":"6","id":6},
                {'label':"初中","value":"7","id":7},
                {'label':"小学","value":"8","id":8},
                {'label':"文盲或半文盲","value":"9","id":9},
                {'label':"不详","value":"10","id":10},
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        professionalFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                {'label':"专业技术人员","value":"1","id":1},
                {'label':"职员及管理人员","value":"2","id":2},
                {'label':"企业领导","value":"3","id":3},
                {'label':"学生或儿童","value":"4","id":4},
                {'label':"商业及服务业人员","value":"5","id":5},
                {'label':"离退休人员","value":"6","id":6},
                {'label':"自由职业或无固定职业人员","value":"7","id":7},
                {'label':"其它职业","value":"8","id":8},
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        exhaustFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                {label:"无",value:"1"},
                {label:"油烟机",value:"2"},
                {label:"换气扇",value:"3"},
                {label:"烟囱",value:"4"},
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        energyFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                {label:"液化气",value:"1"},
                {label:"煤",value:"2"},
                {label:"天然气",value:"3"},
                {label:"沼气",value:"4"},
                {label:"柴火",value:"5"},
                {label:"其他",value:"6"},
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        waterFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                {label:"自来水",value:"1"},
                {label:"经净化过滤的水",value:"2"},
                {label:"井水",value:"3"},
                {label:"河湖水",value:"4"},
                {label:"塘水",value:"5"},
                {label:"其他",value:"6"},
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        toiletFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                {label:"卫生厕所",value:"1"},
                {label:"一格或二格粪池式",value:"2"},
                {label:"马桶",value:"3"},
                {label:"露天粪坑",value:"4"},
                {label:"简易棚厕",value:"5"},
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        acorralFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                {label:"无",value:"1"},
                {label:"单设",value:"2"},
                {label:"室内",value:"3"},
                {label:"室外",value:"4"},
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        bloodFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                { 'label': "A型", "value": "1", "id": 1 },
                { 'label': "B型", "value": "2", "id": 2 },
                { 'label': "AB型", "value": "4", "id": 4 },
                { 'label': "O型", "value": "3", "id": 3 },
                { 'label': "不详或其它", "value": "5", "id": 5 },
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        exposeFormatter: function (item) {
            if (!item) {
                return ""
            }
            var value = item
            if (typeof value === "string") {
                value = JSON.parse(value || "[]")
            }
            const items = [
                { label: "化学品", value: "1" },
                { label: "毒物", value: "2" },
                { label: "射线", value: "3" },
            ];
            const labels = value.map(value => {
                const item = items.find(item => item.value === value);
                return item ? item.label : null;
            });
            return labels.join("、") || "无"
        },
        drugIrritabilityFormatter(item){
            if (!item) {
                return ""
            }
            var value = item.value
            if (typeof value === "string") {
                value = JSON.parse(value || "[]")
            }
            const items = [
                { label: "青每素", value: "1" },
                { label: "碣胺类", value: "2" },
                { label: "链霉素", value: "3" },
                { label: "其它", value: "4" },
                { label: "头孢类", value: "5" },
                { label: "维生素B1", value: "6" },
                { label: "地卡因", value: "7" },
                { label: "破伤风抗霉素", value: "8" },
            ];
            const labels = value.map(value => {
                const item = items.find(item => item.value === value);
                return item ? item.label : null;
            });
            if(item.remark){
                labels.push("其它：" + item.remark)
            }
            return labels.join("、") || "无"

        },
        disabilityFormatter(item){
            if (!item) {
                return ""
            }
            var value = item.value
            if (typeof value === "string") {
                value = JSON.parse(value || "[]")
            }
            const items = [
                { label: "肢体残疾", value: "1" },
                { label: "智力残疾", value: "2" },
                { label: "精神残疾", value: "3" },
                { label: "其他残疾", value: "4" },
            ];
            const labels = value.map(value => {
                const item = items.find(item => item.value === value);
                return item ? item.label : null;
            });
            if(item.remark){
                labels.push("其他残疾：" + item.remark)
            }
            return labels.join("、") || "无"

        },
        illnessFormatter: function (items) {
            if (!items) {
                return "无";
            }
            if (items.has == 0 || !items.has) {
                return "无";
            } else {
                const data = [
                    {label:"高血压",value:"1"},
                    {label:"糖尿病",value:"2"},
                    {label:"冠心病",value:"3"},
                    {label:"慢性阻塞性肺疾病",value:"4"},
                    {label:"严重精神障碍",value:"5"},
                    {label:"结核病",value:"6"},
                    {label:"肝炎",value:"7"},
                    {label:"心脏病",value:"8"},
                    {label:"过敏性疾病",value:"9"},
                    {label:"哮喘",value:"10"},
                    {label:"癫痫",value:"11"},
                    {label:"脑梗",value:"12"},
                    {label:"血脂异常",value:"13"},
                    {label:"脑辛中",value:"14"},
                    {label:"其它",value:"15"}
                    ]

                const result = items.list.map(item => {
                    const item1 = data.find(item2 => item2.value === item.illnessName);
                    const name = item1 ? item1.label : "无"
                    let res =  `${item.time}-${name}`
                    if(item.remark){
                        res = res + ":" +item.remark
                    }
                    return res
                });

                return result.join(";");
            }
        },
        surgeryFormatter: function (items) {
            if (!items) {
                return "无";
            }
            if (items.has == 0 || !items.has) {
                return "无";
            } else {
                const result = items.list.map(item => `${item.time}-${item.surgeryName}`).join('，');
                return result;
            }
        },
        traumaFormatter: function (items) {
            if (!items) {
                return "无";
            }
            if (items.has == 0 || !items.has) {
                return "无";
            } else {
                const result = items.list.map(item => `${item.time}-${item.traumaName}`).join('，');
                return result;
            }
        },
        transfusionFormatter: function (items) {
            if (!items) {
                return "无";
            }
            if (items.has == 0 || !items.has) {
                return "无";
            } else {
                const result = items.list.map(item => `${item.time}-${item.transfusionName}`).join('，');
                return result;
            }
        },
        maritalFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                { 'label': "未婚", "value": "1", "id": 1 },
                { 'label': "已婚", "value": "2", "id": 2 },
                { 'label': "丧偶", "value": "3", "id": 3 },
                { 'label': "离异", "value": "4", "id": 4 },
                { 'label': "未知", "value": "5", "id": 5 },
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
        syztFormatter: function (value) {
            if (!value) {
                return "无"
            }
            const items = [
                { 'label': "未育", "value": "1", "id": 1 },
                { 'label': "已育", "value": "2", "id": 2 },
                { 'label': "未知", "value": "3", "id": 3 },
            ];
            const item = items.find(item => item.value === value);
            return item ? item.label : "无"
        },
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
        },
        handleClick() {
            if (this.activeValue == "1") {
                this.formData = tabdata.tab1form
            }
        },
    },
    mounted() {
    }
}
</script>

<style scoped></style>
