import Vue from 'vue'

/**权限指令**/
const has = Vue.directive('has', {
    bind: function (el, binding, vnode) {
        // 获取页面按钮权限
        let btnPermissionsArr = [];
        if(binding.value){
            // 如果指令传值，获取指令参数，根据指令参数和当前登录人按钮权限做比较。
            btnPermissionsArr = Array.of(binding.value);
        }else{
            // 否则获取路由中的参数，根据路由的btnPermissionsArr和当前登录人按钮权限做比较。
            btnPermissionsArr = vnode.context.$route.meta.btnPermissions;
        }
        if (!Vue.prototype.$_has(btnPermissionsArr)) {
            el.style.display = 'none';
        }
    }
});
// 权限检查方法
Vue.prototype.$_has = function (value) {
    // let userType = localStorage.getItem("userType");
    // if(userType == '1'){
    //     return true
    // }
    let isExist = false;
    // 获取用户按钮权限
    let btnPermissionsStr = localStorage.getItem("btnPermissions");
    if (btnPermissionsStr == undefined || btnPermissionsStr == null) {
        return false;
    }
    btnPermissionsStr = JSON.parse(btnPermissionsStr)
    if(value.length){
        var temp = value[0]
        if (btnPermissionsStr.includes(temp)) {
            isExist = true;
        }
    }
    return isExist;
};
export {has}
