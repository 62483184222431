<template>
    <el-upload action="/api/upload" :headers="headers" :on-success="handleSuccess" :on-error="handleError" :before-upload="beforeUpload">
        <div>
            <el-image v-if="value" style="height: 100px" :src="value" :preview-src-list="[value]" />
        </div>
        <el-button slot="trigger" :size="size" :type="type">上传文件</el-button>
    </el-upload>
</template>

<script>
export default {
    name: "upload-image2",
    props: {
        value: String,
        size: {
            type: String,
            default: 'mini'
        },
        type: {
            type: String,
            default: 'default'
        },
        url: {
            type: String,
            default: '/api/cms/upload'
        }
    },
    data() {
        return {
            uploadUrl: "",
        }
    },
    computed: {
        headers() {
            const token = localStorage.getItem('token')
            return {
                Authorization: `Bearer ${token}`
            }
        }
    },
    methods: {
        // 文件上传前的钩子，可进行一些校验或处理
        // eslint-disable-next-line
        beforeUpload(file) {
            // 例如，可以检查文件类型、大小等
            // return false 可阻止上传
        },
        // eslint-disable-next-line
        handleSuccess(response, file, fileList) {
            // 假设服务器返回的响应包含文件 URL
            this.uploadUrl = response.data[0].url;
            // 更新父组件的 form.image
            this.$emit("input", this.uploadUrl);
        },
        // eslint-disable-next-line
        handleError(error, file, fileList) {
            console.error("上传失败", error);
        },
    }
}
</script>