import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '/peis',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/login', component: () => import(`@/pages/main/login`)},
        {path: '/logout', component: () => import(`@/pages/main/logout`)},
        {
            path: '/main/password', component: () => import(`@/pages/main/layout_blank`),
            children: [
                {path: '', component: () => import(`@/pages/main/password`)},
            ]
        },
        {
            path: '/company', component: () => import(`@/pages/main/layout_admin`),
            children: [
                {path: 'dashboard', component: () => import(`@/pages/company/dashboard`)},
                {path: 'user', component: () => import(`@/pages/settings/user/index`)},
                {path: 'expert', component: () => import(`@/pages/company/expert`)},
                {path: 'role', component: () => import(`@/pages/company/role`)},
                {path: 'account', component: () => import(`@/pages/company/account`)},
                {path: 'account/transfer', component: () => import(`@/pages/company/transfer`)},
                {path: 'company', component: () => import(`@/pages/company/company`)},
                {path: 'Facility', component: () => import(`@/pages/company/facility`)},
                {path: 'healthInsurance', component: () => import(`@/pages/company/healthInsurance`)},
                {path: 'statistics/yufenzu', component: () => import(`@/pages/statistics/yufenzu`)},
                {path: 'statistics/dabing', component: () => import(`@/pages/statistics/dabing`)},
                {path: 'statistics/manbing', component: () => import(`@/pages/statistics/manbing`)},
                {path: 'hall/scheduling', component: () => import(`@/pages/company/hall/scheduling`)},
                {path: 'hall/workload', component: () => import(`@/pages/company/hall/workload`)},
                {path: 'jkxj/data', component: () => import(`@/pages/company/jkxj/index`)},
                {path: 'jkdr/data', component: () => import(`@/pages/company/jkdr/index`)},
                {path: 'jkdr/statistics', component: () => import(`@/pages/company/jkdr/statistics`)},
            ]
        },
        {
            path: '/main', component: () => import(`@/pages/main/layout_admin`),
            children: [
                {path: '', redirect: '/main/dashboard'},

                {path: 'dashboard', component: () => import(`@/pages/dashboard`)},

                {path: 'phr', component: () => import(`@/pages/phr`)},
                {path: 'phr/progress', component: () => import(`@/pages/phr/progress`)},
                {path: 'phr/detail/:id', component: () => import(`@/pages/phr/detail`)},
                {path: 'phr/check', component: () => import(`@/pages/phr/check`)},
                {path: 'phr/transfer', component: () => import(`@/pages/phr/transfer`)},

                {path: 'hi/task', component: () => import(`@/pages/hi/index`)},
                {path: 'hi/model', component: () => import(`@/pages/hi/model`)},

                {path: 'hut/weijizhi', component: () => import(`@/pages/xiaowu/weijizhi`)},
                {path: 'hut/hut', component: () => import(`@/pages/xiaowu/hut`)},
                {path: 'hut/device', component: () => import(`@/pages/xiaowu/device`)},
                {path: 'hut/dxcl', component: () => import(`@/pages/xiaowu/dxcl`)},
                {path: 'hut/report', component: () => import(`@/pages/xiaowu/report`)},

                {path: 'healthAssessment/hra3', component: () => import(`@/pages/healthAssessment/hra3`)},
                {path: 'healthAssessment/jksc', component: () => import(`@/pages/healthAssessment/jksc`)},
                {path: 'healthAssessment/hra2', component: () => import(`@/pages/healthAssessment/hra2`)},
                {path: 'healthAssessment/risk', component: () => import(`@/pages/healthAssessment/risk`)},
                {path: 'healthAssessment/vitality', component: () => import(`@/pages/healthAssessment/vitality`)},


                {path: 'evaluate/common', component: () => import(`@/pages/evaluate/common`)},
                {path: 'evaluate/chushai', component: () => import(`@/pages/evaluate/chushai`)},
                {path: 'evaluate/slc90', component: () => import(`@/pages/evaluate/slc90`)},
                {path: 'evaluate/temporary', component: () => import(`@/pages/evaluate/temporary`)},
                {path: 'evaluate/custom', component: () => import(`@/pages/evaluate/custom`)},
                {path: 'evaluate/fivehighs', component: () => import(`@/pages/evaluate/index`)},

                {path: 'follow-up/task', component: () => import(`@/pages/followup/task`)},
                {path: 'follow-up/log', component: () => import(`@/pages/followup/log`)},

                {path: 'positive/new', component: () => import(`@/pages/positive/new`)},
                {path: 'positive/task', component: () => import(`@/pages/positive/task`)},
                {path: 'positive/log', component: () => import(`@/pages/positive/log`)},
                {path: 'positive/setting', component: () => import(`@/pages/positive/setting`)},

                {path: 'diet/task', component: () => import(`@/pages/diet/task`)},
                {path: 'diet/principles', component: () => import(`@/pages/diet/principles`)},
                {path: 'diet/dtcm', component: () => import(`@/pages/diet/dtcm`)},
                {path: 'diet/cookbook', component: () => import(`@/pages/diet/cookbook`)},
                {path: 'diet/ingredients', component: () => import(`@/pages/diet/ingredients`)},
                {path: 'diet/dishes', component: () => import(`@/pages/diet/dishes`)},

                {path: 'fitness/task', component: () => import(`@/pages/fitness/task`)},
                {path: 'fitness/principles', component: () => import(`@/pages/fitness/principles`)},
                {path: 'fitness/template', component: () => import(`@/pages/fitness/template`)},
                {path: 'fitness/items', component: () => import(`@/pages/fitness/items`)},

                {path: 'assessment/person', component: () => import(`@/pages/assessment`)},
                {path: 'questionnaire', component: () => import(`@/pages/assessment/questionnaire`)},
                {path: 'assessment_view', component: () => import(`@/pages/assessment/assessment_view`)},
                {path: 'assessment/group', component: () => import(`@/pages/assessment/group`)},

                {path: 'settings/user', component: () => import(`@/pages/settings/user/index`)},
                {path: 'settings/dict', component: () => import(`@/pages/settings/dict`)},
                {path: 'settings/target', component: () => import(`@/pages/settings/target`)},
                {path: 'settings/sms', component: () => import(`@/pages/settings/sms`)},

                {path: 'message', component: () => import(`@/pages/main/messages`)},
                {path: 'call', component: () => import(`@/pages/main/call`)},
                {path: 'wechat', component: () => import(`@/pages/main/wechat`)},
                {path: 'oplog', component: () => import(`@/pages/oplog`)},
                {path: 'help', component: () => import(`@/pages/help`)},

                {path: 'industrial/data', component: () => import(`@/pages/industrial/index`)},
                {path: 'industrial/report', component: () => import(`@/pages/industrial/report`)},
                {path: 'industrial/adapter', component: () => import(`@/pages/industrial/adapter`)},
                {path: 'industrial/trans', component: () => import(`@/pages/industrial/trans`)},
                {path: 'industrial/statistics', component: () => import(`@/pages/industrial/statistics`)},

                {path: 'tools/test', component: () => import(`@/pages/tools/test_tools`)},

                {path: '*', component: () => import(`@/pages/main/notFound`)}
            ]
        },
        {path: '*', component: () => import(`@/pages/main/notFound`)}
    ]
})

export default router
