<template>
    <el-popover trigger="click" placement="top">
        <div v-for="(item,index) in report.level_tag" :key="index" style="padding: 5px" v-if="report.level_tag">
            <el-tag type="danger" size="medium" >{{item}}</el-tag>
        </div>
        <div  v-if="report.audience_tag" style="padding: 5px">
            <el-tag size="medium" >{{report.audience_tag}}</el-tag>
        </div>
        <div slot="reference" class="name-wrapper">
            <el-tag size="medium">共{{ report|tagListFormatter }}个标签</el-tag>
        </div>
    </el-popover>
</template>

<script>
    export default {
        name: 'popover-pg',
        props: {
            report: {
                type: Object, // 明确指定 prop 的类型
                default: function() { // 使用一个工厂函数返回默认值
                    return {};
                }
            }
        },
        data() {
            return {
                loading: false,
                tableData: {}
            }
        },
        filters: {
            tagListFormatter: function (item) {
                console.log(item)
                var count = 0;
                if(item.audience_tag){
                    count += 1
                }
                if(item.level_tag){
                    count += item.level_tag.length
                }
                return count;
            }

        },
        methods: {
        }
    }
</script>
